import React, { lazy, Suspense } from 'react';

const IntroTextSpravneWebStrankyCCh = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'IntroTextSpravneWebStrankyCChLazy' */ './IntroTextSpravneWebStrankyCCh'));

function IntroTextSpravneWebStrankyCChLazy(props) {
    return (
        <div>
            <Suspense fallback={<div>Loading IntroTextSpravneWebStrankyCCh ...</div>}>
                <IntroTextSpravneWebStrankyCCh {...props} />
            </Suspense>
        </div>
    );
}

export default IntroTextSpravneWebStrankyCChLazy;